.fade {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade.show {
    opacity: 1;
    transform: translateY(0) scale(1);
}

.zoom {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.zoom.show {
    opacity: 1;
    transform: scale(1);
}

.rotate {
    opacity: 0;
    transform: translateY(20px) scale(0.9) rotateX(-180deg);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.rotate.show {
    opacity: 1;
    transform: translateY(0) scale(1) rotateX(0deg);
}